import { TimelineLite, CSSPlugin } from 'gsap/all';
import throttle from 'lodash.throttle';

// Without this line, CSSPlugin gets dropped by webpacks treeshaking!
// https://greensock.com/docs/NPMUsage
const plugins = [CSSPlugin]; // eslint-disable-line no-unused-vars

export const header = () => {
  // Menu
  const menuHamburger = document.querySelector('#menu-hamburger');
  const siteHeader = document.querySelector('.site-header');
  const mobileMenu = document.querySelector('#primary-navigation-mobile-menu');
  const mobileMenuItems = [...mobileMenu.querySelectorAll('li a')];

  let menuOpen = false;
  let windowHeight;
  let siteHeaderHeight;
  let mobileMenuHeight;

  // Search overlay
  const searchOverlayToggles = document.querySelectorAll('.search-overlay-toggle');
  const searchOverlay = document.querySelector('.search-overlay');

  let searchOverlayOpen = false;

  /**
   *
   *
   *   @function clearSearchOverlayAnimationStyles
   *
   *   Remove the styles applied to the search overlay during greensock animation
   *
   */
  const clearSearchOverlayAnimationStyles = () => {
    searchOverlay.removeAttribute('style');
  };

  /**
   *
   *   @function openSearchOverlay
   *
   *   Opens the search overlay
   *
   */
  const openSearchOverlay = () => {
    const searchOverlayOpenTL = new TimelineLite();
    searchOverlayOpenTL.to(searchOverlay, 0.12, {
      autoAlpha: 1,
      display: 'block',
    });
  };

  /**
   *
   *   @function closeSearchOverlay
   *
   *   Closes the search overlay
   *
   */
  const closeSearchOverlay = () => {
    const searchOverlayCloseTL = new TimelineLite({
      onComplete: clearSearchOverlayAnimationStyles,
    });
    searchOverlayCloseTL.to(searchOverlay, 0.24, { autoAlpha: 0 });
  };

  /**
   *
   *   @function toggleSearchOverlay
   *
   *   Handles toggeling the search overlay between open and closed.
   *
   */
  const toggleSearchOverlay = e => {
    e.preventDefault();
    if (searchOverlayOpen) {
      closeSearchOverlay();
    } else {
      openSearchOverlay();
    }
    searchOverlayOpen = !searchOverlayOpen;
  };

  /**
   *
   *   @function calculateMobileMenuHeight
   *
   *   Calculates the target height that the mobile menu will open to.
   *
   */
  const calculateMobileMenuHeight = () => {
    windowHeight = window.innerHeight;
    siteHeaderHeight = siteHeader.clientHeight;
    mobileMenuHeight = windowHeight - siteHeaderHeight;
  };

  /**
   *
   *   @function clearMenuAnimationStyles
   *
   *   Remove the styles applied to the menu during greensock animation
   *
   */
  const clearMenuAnimationStyles = () => {
    mobileMenu.removeAttribute('style');
    mobileMenuItems.forEach(mobileMenuItem => {
      mobileMenuItem.removeAttribute('style');
    });
  };

  /**
   *
   *   @function openMenu
   *
   *   Handles opening of the mobile menu.
   *
   */
  const openMenu = () => {
    const menuOpenTL = new TimelineLite();
    menuOpenTL
      .to(mobileMenu, 0.3, { height: mobileMenuHeight })
      .staggerTo(mobileMenuItems, 0.24, { y: '0%', autoAlpha: 1 }, 0.06, '-=0.12');
  };

  /**
   *
   *   @function closeMenu
   *
   *   Handles closing of the mobile menu.
   *
   */
  const closeMenu = () => {
    if (searchOverlayOpen) {
      closeSearchOverlay();
    }
    const menuCloseTL = new TimelineLite({ onComplete: clearMenuAnimationStyles });
    menuCloseTL.to(mobileMenuItems, 0.12, { autoAlpha: 0 }).to(mobileMenu, 0.3, { height: 0 });
  };

  /**
   *
   *   @function toggleMenu
   *
   *   Handles toggeling the menu between open and closed.
   *
   */
  const toggleMenu = e => {
    e.preventDefault();
    if (menuOpen) {
      closeMenu();
    } else {
      openMenu();
    }
    siteHeader.classList.toggle('menu-open');
    menuHamburger.classList.toggle('is-active');
    menuOpen = !menuOpen;
  };

  /**
   *
   *   @function onResize
   *
   *   Fires the relevant functions when the window is resized
   *
   */
  const onResize = () => {
    calculateMobileMenuHeight();
    if (menuOpen) {
      toggleMenu();
    }
    if (searchOverlayOpen) {
      toggleSearchOverlay();
    }
  };

  /**
   *
   *   @function init
   *
   *   Initialise the header functionality
   *
   */
  const init = () => {
    calculateMobileMenuHeight();
    menuHamburger.addEventListener('click', toggleMenu);
    searchOverlayToggles.forEach(searchOverlayToggle => {
      searchOverlayToggle.addEventListener('click', toggleSearchOverlay);
    });
    window.addEventListener('resize', throttle(onResize, 111));
  };

  return { init };
};
