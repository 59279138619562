export const filters = () => {
  /**
   *
   *   @function init
   *
   *   Initialise the filters functionality
   *
   */
  const init = () => {
    $('.filter-toggles button').click(function() {
      const target = $(this).attr('data-target');
      const open = $(this).attr('data-open');

      // Close others
      $('.filter-toggles button')
        .not(this)
        .attr('data-open', false);

      $('.filter-menu')
        .not('.' + target)
        .slideUp();

      if (open === 'true') {
        $('.' + target).slideUp();
        $(this).attr('data-open', false);
      } else {
        $('.' + target).slideDown();
        $(this).attr('data-open', true);
      }
    });
  };

  return { init };
};
