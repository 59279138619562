import throttle from 'lodash.throttle';

export const maps = () => {
  const smallDeviceBreakpoint = 767;
  const mapStyles = [
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#e9e9e9' }, { lightness: 17 }],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [{ color: '#f5f5f5' }, { lightness: 20 }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.fill',
      stylers: [{ color: '#ffffff' }, { lightness: 17 }],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#ffffff' }, { lightness: 29 }, { weight: 0.2 }],
    },
    {
      featureType: 'road.arterial',
      elementType: 'geometry',
      stylers: [{ color: '#ffffff' }, { lightness: 18 }],
    },
    {
      featureType: 'road.local',
      elementType: 'geometry',
      stylers: [{ color: '#ffffff' }, { lightness: 16 }],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [{ color: '#f5f5f5' }, { lightness: 21 }],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [{ color: '#dedede' }, { lightness: 21 }],
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [{ visibility: 'on' }, { color: '#ffffff' }, { lightness: 16 }],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [{ saturation: 36 }, { color: '#333333' }, { lightness: 40 }],
    },
    { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
    {
      featureType: 'transit',
      elementType: 'geometry',
      stylers: [{ color: '#f2f2f2' }, { lightness: 19 }],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.fill',
      stylers: [{ color: '#fefefe' }, { lightness: 20 }],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry.stroke',
      stylers: [{ color: '#fefefe' }, { lightness: 17 }, { weight: 1.2 }],
    },
  ];
  let isSmallDevice = true;
  let heroMap = null;
  const mapToggles = $('.toggle-location');

  /**
   *
   *   @function centerMap
   *
   *
   */
  const centerMap = googleMap => {
    const bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each(googleMap.markers, function(i, marker) {
      const latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
      bounds.extend(latlng);
    });

    // only 1 marker?
    if (googleMap.markers.length === 1) {
      // set center of map
      googleMap.setCenter(bounds.getCenter());
      googleMap.setZoom(16);
    } else {
      // fit to bounds
      googleMap.fitBounds(bounds);
    }
  };

  /**
   *
   *   @function addMarker
   *
   *
   */
  const addMarker = ($marker, googleMap) => {
    const latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

    // Image directory
    const iconBase = `${wsk_vars.template_directory}/assets/img/`;

    // Create marker
    const marker = new google.maps.Marker({
      position: latlng,
      map: googleMap,
      animation: google.maps.Animation.DROP,
      icon: {
        url: `${iconBase}icon-location-marker.png`,
        size: new google.maps.Size(32, 32),
        scaledSize: new google.maps.Size(32, 32),
      },
    });

    // Store the markers on the object
    googleMap.markers.push(marker);
  };

  /**
   *
   *   @function newMap
   *
   *
   */
  const newMap = $map => {
    const newMapArgs = {
      zoom: 16,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      styles: mapStyles,
      disableDefaultUI: true,
    };

    // Grab the markers
    const $markers = $map.find('.marker');

    // Create the map
    const googleMap = new google.maps.Map($map[0], newMapArgs);

    // add a markers reference
    googleMap.markers = [];

    // Add markers
    $markers.each(function() {
      addMarker($(this), googleMap);
    });

    // Center map
    centerMap(googleMap);

    return googleMap;
  };

  /**
   *
   *   @function clearHeroMarkers
   *
   *
   */
  const clearHeroMarkers = () => {
    const markersArray = heroMap.markers;
    if (markersArray) {
      for (let i = 0; i < markersArray.length; i += 1) {
        markersArray[i].setMap(null);
      }

      // Remove from the object
      heroMap.markers = [];
    }
  };

  /**
   *
   *   @function updateHeroMarker
   *
   *
   */
  const updateHeroMarker = $marker => {
    clearHeroMarkers();

    const latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));
    heroMap.setCenter(latlng);

    addMarker($marker, heroMap);
  };

  /**
   *
   *   @function toggleMap
   *
   *
   */
  const toggleMap = e => {
    e.preventDefault();
    const { toggle } = e.data;

    const mapContainer = $(toggle)
      .closest('.location-wrapper')
      .find('.location-map');

    const newState = $(toggle).attr('data-state') === 'open' ? 'closed' : 'open';

    $(toggle).attr('data-state', newState);

    // Bind click events
    if (isSmallDevice) {
      if (newState === 'open') {
        mapContainer.animate({ height: '400px' }, 200);
      } else {
        mapContainer.animate({ height: '0' }, 200);
      }
    } else {
      updateHeroMarker($(toggle));
    }
  };

  /**
   *
   *   @function setIsSmallDevice
   *
   *   Checks the window width and sets the isSmallDevice flag
   *
   */
  const setIsSmallDevice = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth > smallDeviceBreakpoint) {
      isSmallDevice = false;
    } else {
      isSmallDevice = true;
    }
  };

  /**
   *
   *   @function initMaps
   *
   */
  const initMaps = () => {
    $('.location-map').each(function() {
      newMap($(this));
    });

    // Save the hero map so that we can update location markers later
    $('.location-map-hero').each(function() {
      heroMap = newMap($(this));
    });
  };

  /**
   *
   *   @function onResize
   *
   */
  const onResize = () => {
    setIsSmallDevice();
  };

  /**
   *
   *   @function init
   *
   *   Initialise the map functionality
   *
   */
  const init = () => {
    setIsSmallDevice();
    initMaps();

    // Attach resize event handler
    window.addEventListener('resize', throttle(onResize, 111));

    // Attach click event handlers
    mapToggles.each(function() {
      $(this).on(
        'click',
        {
          toggle: this,
        },
        toggleMap
      );
    });
  };

  return { init };
};
