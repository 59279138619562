import { TimelineLite } from 'gsap/all';

export const strikethroughHero = () => {
  const heroStikethroughContent = document.querySelector(
    '.content-section-strikethrough-hero .content'
  );

  /**
   *
   *   @function animateWords
   *
   *   Loop over the span tags "words" and animate
   */
  const animateWords = () => {
    const heroStikethroughWords = [...heroStikethroughContent.querySelectorAll('span')];

    const animateWordsTL = new TimelineLite();
    animateWordsTL.staggerTo(
      heroStikethroughWords,
      0.06,
      { css: { className: '+=strikethrough' } },
      0.03
    );
  };

  /**
   *
   *   @function init
   *
   *
   */
  const init = () => {
    if ($('.content-section-strikethrough-hero').length > 0) {
      setTimeout(function() {
        animateWords();
      }, 1800);
    }
  };

  return { init };
};
