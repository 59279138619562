export const forms = () => {
  /**
   *
   *   @function initForms
   *
   */
  const initForms = () => {
    $('.form-control').focus(function() {
      $(this)
        .closest('.gfield')
        .addClass('focused');
    });

    $('.form-control').blur(function() {
      const inputValue = $(this).val();
      if (inputValue === '') {
        $(this).removeClass('filled');
        $(this)
          .closest('.gfield')
          .removeClass('focused');
      } else {
        // Prevent the label returning to the placeholder position
        $(this).addClass('filled');
      }
    });
  };

  /**
   *
   *   @function init
   *
   *   Initialise the forms functionality
   *
   */
  const init = () => {
    initForms();
  };

  return { init };
};
