import { filters } from './components/filters';
import { forms } from './components/forms';
import { header } from './components/header';
import { maps } from './components/maps';
import { strikethroughHero } from './components/strikethroughHero';

// eslint-disable-next-line no-unused-vars
import slider from './components/slider';

const initModules = () => {
  filters().init();
  forms().init();
  header().init();
  maps().init();
  strikethroughHero().init();
};

const initSite = () => {
  initModules();
};

if (document.addEventListener) document.addEventListener('DOMContentLoaded', initSite);
else window.attachEvent('onload', initSite);
